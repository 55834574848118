/* Scrollbar styles */

#root {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

/* Optional: Hover styles */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}